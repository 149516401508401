import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isHttpLoadingSubject$ = new BehaviorSubject(false);

  public get isHttpLoading$(): Observable<boolean> {
    return this.isHttpLoadingSubject$.asObservable();
  }

  public setHttpLoading(isHttpLoading: boolean): void {
    setTimeout(() => {
      this.isHttpLoadingSubject$.next(isHttpLoading);
    }, 0);
  }
}
