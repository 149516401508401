import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LoaderService } from '../../services/loader/loader.service';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requestCount = 0;

  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requestCount += 1;

    if (this.requestCount === 1) {
      this.loaderService.setHttpLoading(true);
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.removeRequest();
      }),
    );
  }

  private removeRequest() {
    this.requestCount -= 1;

    if (this.requestCount === 0) {
      this.loaderService.setHttpLoading(false);
    }
  }
}
